import React from 'react';
import PropTypes from 'prop-types';
import { useSiteContext } from '../../SiteContext';
import ArticleList from '../../News/Article/List';
import { newsFeedImageConfigurations } from '../../../lib/imageConfigurations';
import { allNewsCategoryIds } from '../../../lib/mainCategoryIds';
import articlesConnectionQuery
from '../../../lib/queries/news/articlesConnectionQuery.graphql';
import withQuery from '../../../lib/withQuery';
import { minimumPositiveValue } from '../../../lib/isPositiveSentiment';

const ArticleListWithQuery = withQuery(ArticleList);

const JobSearchNews = ({ tagSlugs }) => {
  const {
    newsboard,
  } = useSiteContext();

  if (tagSlugs.length === 0) return null;

  const errorMessage = 'Error loading news articles...';

  const variables = {
    newsboard,
    pageviews: false,
    sort: 'CREATED_AT',
    first: 12,
    imageConfigurations: newsFeedImageConfigurations,
    categoryIds: allNewsCategoryIds,
    tagSlugs,
    minScores: {
      name: 'positive',
      value: minimumPositiveValue,
    },
  };

  return (
    <ArticleListWithQuery
      query={articlesConnectionQuery}
      variables={variables}
      errorMessage={errorMessage}
    />
  );
};

JobSearchNews.propTypes = {
  tagSlugs: PropTypes.arrayOf(PropTypes.string),
};

JobSearchNews.defaultProps = {
  tagSlugs: [],
};

export default JobSearchNews;
