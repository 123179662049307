import React, { Fragment } from 'react';
import { FetchMoreQueryOptions } from '@apollo/client';
import { StyledList } from '@fuww/library/src/Article';
import LoadMore from '../../LoadMore';
import loadMore from '../../../lib/queries/loadMore';
import {
  ArticlesConnectionQuery,
  ArticlesConnectionQueryVariables,
  AuthorByEmailQuery,
  AuthorByEmailQueryVariables,
} from '../../../lib/graphql/api/graphql';
import NewsFeedListItem from './NewsFeedListItem';

const ArticleList = <
  Q extends ArticlesConnectionQuery | AuthorByEmailQuery,
  V extends ArticlesConnectionQueryVariables | AuthorByEmailQueryVariables,
>({
    variables,
    fetchMore,
    data,
    loading,
    showLoader,
    shouldLoadMore = true,
  }: {
    data?: Q;
    variables: V;
    fetchMore: (variables: FetchMoreQueryOptions<V>) => void;
    loading: boolean;
    showLoader: boolean;
    shouldLoadMore?: boolean;
  }) => {
  const connection = (data as Record<string, unknown> | undefined)
    ?.universalNewsArticlesConnection
    ? (data as ArticlesConnectionQuery | undefined)
      ?.universalNewsArticlesConnection
    : (data as AuthorByEmailQuery | undefined)
      ?.universalAuthorByEmail
      ?.newsArticlesConnection;
  const edges = connection?.edges;
  const hasNextPage = connection?.pageInfo.hasNextPage === true;
  const connectionField = (data as Record<string, unknown> | undefined)
    ?.universalNewsArticlesConnection
    ? null : 'universalAuthorByEmail';
  const field = connectionField
    ? 'newsArticlesConnection' : 'universalNewsArticlesConnection';

  return (
    <>
      <StyledList className="fu-mdc-list">
        {Array.isArray(edges) && edges.length > 0 ? (
          edges.map((edge, index) => {
            const { node } = edge;

            return (
              <NewsFeedListItem
                article={node}
                variables={{
                  pageviews: false,
                  ...variables,
                }}
                isFirstImage={index === 0}
                key={node.id.toString()}
                showLoader={showLoader}
              />
            );
          })
        ) : (showLoader ? (Array.from({ length: 4 }, (_, index) => (
          <Fragment key={`skeleton-${index}`}>
            <NewsFeedListItem
              showLoader
              variables={{
                pageviews: false,
                ...variables,
              }}
              key={`skeleton-${index}`}
              isFirstImage={false}
            />
          </Fragment>
        ))) : null)}
      </StyledList>
      {shouldLoadMore && (
      <LoadMore
        loading={loading}
        hasNextPage={hasNextPage}
        fetchMore={
            () => loadMore({
              variables,
            })(data, field, fetchMore, connectionField)
          }
      />
      )}
    </>
  );
};

export default ArticleList;
